<div class="col-12">
  <nb-card [nbSpinner]="isLoading">
    <nb-card-header style="border: 0;">
      {{ label }}
    </nb-card-header>

    <nb-card-body nbspinner class="d-flex flex-column align-items-center col-12">
      <form [formGroup]="formGroup">
        <div class="w-100 d-flex flex-column">
          <div class="w-100 col-12 col-md-12">

            <label class="ml-3">Senha</label>

            <nb-form-field class="col-12">
              <input class="input" [type]="shouldHidePassword ? 'password' : 'text'"
                     formControlName="password" fullWidth nbInput shape="rectangle">
              <button type="button" nbSuffix nbButton ghost
                      (click)="shouldHidePassword = !shouldHidePassword">
                <nb-icon [icon]="!shouldHidePassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                </nb-icon>
              </button>
            </nb-form-field>
          </div>

          <div class="password-area">
            <button nbButton shape="round" size="small" outline
                    class="ml-2 button--secondary__right-spacing col-4" (click)="cancelUpdate()">
              Cancelar
            </button>
            <button nbButton shape="round"
                    class="button--primary ml-2 col-6" (click)="checkPassword()">
              Confirmar senha
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>
